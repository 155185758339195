import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby';
// import { Link } from 'gatsby'

// import Layout from '../components/layout'
// import Image from '../components/image'
import Logo from '../images/thenumber73-logo.svg';

import '../../node_modules/bulma/css/bulma.min.css';
import '../components/layout.css';
import 'animate.css';

const flexboxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '1rem'
}

const IndexPage = ({data}) => (
  <>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'The Number 73 is a full-service creative agency that develops profitable and unique game plans for businesses to promote their brand, products, and services.' },
        { name: 'keywords', content: 'creative agency, design, marketing, web, development' }
      ]}
    >
      <html lang="en" />
    </Helmet>
    <div style={flexboxStyle}>
      <section>
        <Logo className="testingSVG animated fadeIn delay-1s" />
        <hr className="animated fadeIn delay-1s" />
        <h1 className="title is-1 animated fadeIn delay-2s" style={{fontWeight: 900}}>
          We create profitable strategies for our clients.
        </h1>
        <h3 className="subtitle1 animated fadeIn delay-2s">WE CREATE TO CHANGE THE WORLD</h3>
        <hr className="animated fadeIn delay-2s" />
        <p className="animated fadeIn delay-3s"><strong style={{color: '#fff'}}>The Number 73</strong> is a full-service creative agency that develops profitable <br/> and unique game plans for businesses to promote their brand, products, and services.</p>
        <div className="ctaContainer phoneCta animated fadeIn delay-4s">
          <div>Call / Txt: 908.242.9963 <span className="is-hidden-mobile">|</span> </div>
          <div>Email: info@thenumber73.com</div>
        </div>
      </section>
    </div>
  </>
  // <Layout>
  //   <section style={background}>
  //     <h1 className="title is-1">Hi people</h1>
  //     <p>Welcome to your new Gatsby site.</p>
  //     <p>Now go build something great.</p>
  //     <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
  //       <Image />
  //     </div>
  //   </section>
  //   {/* <Link to="/page-2/">Go to page 2</Link> */}
  // </Layout>
)

export const query = graphql`
query HomePage {
  site {
    siteMetadata {
      title
    }
  }
}
`

export default IndexPage
